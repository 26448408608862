function buttonBackgroundStyle({ background }) {
  return !background ? '' : `background-color: ${background};`;
}

function buttonSmallStyle({ small }) {
  return !small
    ? ''
    : `
      font-size: 14px;
      padding: 4px 16px;`;
}

function uppercaseStyle({ uppercase }) {
  return !uppercase ? '' : 'text-transform: uppercase;';
}

export default {
  global: {
    colors: {
      brand: '#FFC940',
      focus: '#D04A02',
      selected: 'brand',
      border: {
        dark: '#FFFFFF',
        light: '#212121',
      },
      icon: {
        dark: '#FFFFFF',
        light: '#212121',
      },
      text: {
        dark: '#FFFFFF',
        light: '#212121',
      },
      'accent-1': '#D04A02',
      'accent-2': '#D04A02',
      'accent-3': '#279D8F',
      'accent-4': '#EFC07A',
      'accent-5': '#EA8C00',
      'accent-6': '#FFB600',
      'dark-1': '#212121',
      'dark-2': '#2D2D2D',
      'dark-3': '#7D7D7D',
      'dark-4': '#979797',
      'light-5': '#B0B0B2',
      'light-6': '#EBEBEB',
      'light-7': '#F5F5F5',
      'hero-interior-button': '#FFFFFF',
      'hero-interior-alt-button': '#FFFFFF',
      'mobile-nav-rule': 'gray',
    },
    breakpoints: {
      hero: {
        value: 1024,
      },
    },
    control: {
      border: {
        radius: 'none',
      },
    },
    edgeSize: {
      xlarge: '72px',
    },
    font: {
      family: 'PwC Helvetica Neue, sans-serif',
    },
    input: {
      weight: 400,
    },
    hover: {
      background: {
        color: 'brand',
      },
      color: {
        dark: 'accent-1',
        light: 'accent-1',
      },
    },
  },
  accordion: {
    border: {
      side: 'none',
      color: 'none',
    },
  },
  anchor: {
    color: {
      dark: 'accent-1',
      light: 'accent-2',
    },
    fontWeight: 'normal',
    textDecoration: 'underline',
    hover: {
      textDecoration: 'none',
    },
  },
  button: {
    border: {
      radius: '5px',
      width: '1px',
      color: {
        dark: 'white',
        light: 'accent-1',
      },
    },
    padding: {
      vertical: '10px',
      horizontal: '30px',
    },
    primary: {
      color: {
        dark: 'white',
        light: 'accent-1',
      },
    },
    extend: (props) => {
      return `
        ${buttonBackgroundStyle(props)}
        ${buttonSmallStyle(props)}
        ${uppercaseStyle(props)}


        min-width: ${props.minWidth || 'auto'};
        text-align: center;
        font-weight: bold;

        & svg {
          fill: currentColor;
          stroke: currentColor;
        }
      `;
    },
  },
  formField: {
    border: {
      side: 'all',
    },
    label: {
      margin: {
        horizontal: 'none',
        vertical: 'xsmall',
      },
    },
    extend: () => {
      return `
        position: relative;

        & label {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
        }

        & span {
          font-size: 14px;
          line-height: 1;
          margin: 0;
          position: absolute;
          top: 100%;
        }`;
    },
  },
  heading: {
    font: {
      family: 'PwC ITC Charter, serif',
    },
    weight: 400,
    level: {
      '1': {
        medium: {
          size: '54px',
          height: '55px',
        },
      },
      '2': {
        medium: {
          size: '40px',
          height: '50px',
        },
      },
      '3': {
        font: {
          family: 'PwC Helvetica Neue, sans-serif',
          weight: 'bold',
        },
        medium: {
          size: '28px',
          height: '38px',
        },
      },
      '4': {
        font: {
          family: 'PwC Helvetica Neue, sans-serif',
          weight: 'bold',
        },
        medium: {
          size: '22px',
          height: '28px',
        },
        small: {
          size: '14px',
          height: '22px',
        },
      },
      '5': {
        font: {
          family: 'PwC Helvetica Neue, sans-serif',
          weight: 'bold',
        },
      },
      '6': {
        font: {
          family: 'PwC Helvetica Neue, sans-serif',
          weight: 'bold',
        },
      },
    },
    extend: (props) => {
      return `
        ${uppercaseStyle(props)}
      `;
    },
  },
  layer: {
    background: 'rgba(0, 0, 0, 0.8)',
    overlay: {
      background: 'rgba(0, 0, 0, 0.8)',
    },
  },
  paragraph: {
    small: {
      size: '16px',
      height: '32px',
    },
    medium: {
      size: '18px',
      height: '28px',
    },
  },
  select: {
    icons: {
      color: {
        dark: 'light-1',
        light: 'dark-1',
      },
    },
    options: {
      text: {
        color: 'dark-1',
        size: '16px',
        weight: 'normal',
      },
    },
  },
  text: {
    small: {
      size: '16px',
      height: '32px',
    },
    medium: {
      size: '18px',
      height: '28px',
    },
  },
};
