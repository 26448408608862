import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Box, Button, Heading, ResponsiveContext, Tab, Tabs, ThemeContext } from 'grommet';
import RichText from '../RichText';

const StyledTab = styled(Tab)`
  position: relative;

  &:before {
    background-color: #2d2d2d;
    bottom: 0;
    content: '';
    display: block;
    height: 18px;
    left: 50%;
    position: absolute;
    opacity: 0;
    transform: translate3d(-50%, 0, 0) rotate(45deg);
    transition: transform 0.1s 0.1s ease, opacity 0.1s 0.1s ease;
    visibility: hidden;
    width: 18px;
    z-index: -1;
  }

  ${({ active }) => {
    return (
      active &&
      `
        &:before {
          transform: translate3d(-50%, 17px, 0) rotate(45deg);
          opacity: 1;
          visibility: visible;
        }

        & button {
          color: #fff;
          background-color: #2d2d2d;
          border-color: #2d2d2d;
          box-shadow: 0px 0px 0px 8px #2d2d2d !important;
        }
  `
    );
  }}
`;

export default function({ cards }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const mobile = ['small', 'hero', 'medium'].includes(size);

        return (
          <ThemeContext.Extend
            value={{
              button: {
                border: { color: 'dark-3', radius: 'none' },
                primary: { color: 'white' },
              },
              tabs: {
                header: {
                  extend: () => {
                    return `
                        position: relative;
                        justify-content: space-between;

                        & button {
                          font-size: 22px !important;
                          line-height: 34px;
                        }

                        &:before {
                          background-color: #ffc940;
                          content: '';
                          display: block;
                          height: 20px;
                          width: 100%;
                          position: absolute;
                          top: 50%;
                          transform: translateY(-50%);
                        }
                      `;
                  },
                },
              },
            }}
          >
            <Tabs pad={mobile ? null : { horizontal: 'xlarge' }} wrap={false}>
              {cards &&
                cards.map(({ body, buttonText, headline, image }) => (
                  <StyledTab
                    title={
                      <Button label={buttonText} minWidth={mobile ? '200px' : '220px'} primary />
                    }
                    plain
                  >
                    <Box
                      align="center"
                      justify="center"
                      animation="fadeIn"
                      direction="row"
                      key={buttonText}
                    >
                      <Box width="large" pad="xlarge">
                        {image && <Img fluid={image.localFile.childImageSharp.fluid} />}
                      </Box>
                      <Box
                        width="large"
                        pad={{ top: 'none', bottom: 'xlarge', horizontal: 'xlarge' }}
                      >
                        {headline && (
                          <Heading level="3" margin="none">
                            {headline}
                          </Heading>
                        )}
                        {body && <RichText body={body} />}
                      </Box>
                    </Box>
                  </StyledTab>
                ))}
            </Tabs>
          </ThemeContext.Extend>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
