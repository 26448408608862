import React from 'react';
import { Box, Heading, Image } from 'grommet';
import RichText from '../RichText';

export default function({ headline, body, image }) {
  return (
    <Box direction="row" pad={{ bottom: 'large' }} width="medium">
      <Box height="40px" width="40px" margin={{ right: 'medium' }}>
        {image && <Image src={image} fit="contain" />}
      </Box>
      <Box>
        {headline && (
          <Heading level="3" margin="none">
            {headline}
          </Heading>
        )}
        {body && <RichText body={body} />}
      </Box>
    </Box>
  );
}
