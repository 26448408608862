import React from 'react';
import { Box, Heading, Image, ThemeContext } from 'grommet';
import RichText from '../RichText';

export default function({ body, image, headline, ...rest }) {
  return (
    <Box align="center" alignContent="center" style={{ textAlign: 'center' }} {...rest}>
      <Box height="64px" width="64px">
        {image && <Image src={image} fit="contain" />}
      </Box>
      {headline && (
        <Heading margin={{ bottom: 'none', top: '30px' }} level="3">
          {headline}
        </Heading>
      )}
      {body && (
        <ThemeContext.Extend value={{ paragraph: { extend: () => `margin-top: 0` } }}>
          <RichText body={body} />
        </ThemeContext.Extend>
      )}
    </Box>
  );
}
