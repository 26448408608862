import React from 'react';
import { Box } from 'grommet';
import ThreeUpCard from './ThreeUpCard';
import SectionHeader from '../SectionHeader';

export default function({ background, headline, body, cards }) {
  return (
    <Box background={background} pad="large">
      <Box pad="medium" justify="center">
        {(headline || body) && (
          <SectionHeader pad={{ top: 'small', bottom: 'large' }} headline={headline} body={body} />
        )}
        <Box justify="between" direction="row-responsive" gap="large">
          {cards && cards.map((card) => <ThreeUpCard basis={`1/${cards.length}`} {...card} />)}
        </Box>
      </Box>
    </Box>
  );
}
