import React from 'react';
import CtaBanner from '../components/CtaBanner';
import Hero from '../components/HeroInteriorAlt';
import StoryList from '../components/StoryList';

export default function({ doc, Link }) {
  if (!doc) return null;

  const { hero, categories, cta } = doc;

  return (
    <>
      <Hero Link={Link} {...hero} />
      {categories &&
        categories.map((stories, index) => {
          const background = (index + 1) % 2 === 0 ? 'light-6' : 'white';
          return (
            <>
              <StoryList Link={Link} background={background} {...stories} />
              {cta && cta.headline && <CtaBanner Link={Link} background={background} {...cta} />}
            </>
          );
        })}
    </>
  );
}
