import React from 'react';
import styled from 'styled-components';
import { Box, ThemeContext, ResponsiveContext, Heading } from 'grommet';
import PricingCardInsightsOfficer from './PricingCardInsightsOfficer';
import GatsbyAnchor from '../GatsbyAnchor';
import RichText from '../RichText';

const Button = styled(GatsbyAnchor).attrs({
  size: 'large',
  color: 'accent-2',
})`
  font-weight: bold;
`;

export default function({
  Link,
  buttonHeadline,
  buttonText,
  buttonUrl,
  description,
  headline,
  tiers,
}) {
  return (
    <ThemeContext.Extend value={{ global: { breakpoints: { small: { value: 1024 } } } }}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const isSmall = ['small', 'hero'].includes(size);

          return (
            <Box
              id="pricing"
              pad={{ vertical: 'xlarge', horizontal: isSmall ? 'large' : 'xlarge' }}
              background="light-7"
              align="center"
              style={{ textAlign: 'center' }}
            >
              {headline && (
                <Heading level="2" margin={{ bottom: 'small' }}>
                  {headline}
                </Heading>
              )}
              {description && (
                <ThemeContext.Extend value={{ paragraph: { medium: { maxWidth: 'auto' } } }}>
                  <RichText body={description} />
                </ThemeContext.Extend>
              )}
              <Box
                direction="row-responsive"
                gap={isSmall ? 'large' : '0px'}
                alignSelf="stretch"
                margin="large"
                style={{ zIndex: 0 }}
              >
                {tiers.map((tier, index) => (
                  <PricingCardInsightsOfficer
                    key={tier.id}
                    Link={Link}
                    {...tier}
                    left={index === 0}
                    center={index === 1}
                    right={index === 2}
                    isSmall={isSmall}
                  />
                ))}
              </Box>
              {buttonHeadline && (
                <Heading level="4" textAlign="center" margin={{ bottom: 'small' }}>
                  {buttonHeadline}
                </Heading>
              )}
              {buttonUrl && buttonText && (
                <Button Link={Link} href={buttonUrl} label={buttonText} />
              )}
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
}
