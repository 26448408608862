import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { Anchor, Heading, Paragraph } from 'grommet';
import GatsbyAnchor from '../GatsbyAnchor';
import { linkResolver } from '../../utils';
import StoryContext from '../../templates/StoryContext';

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`;

function options(Link, postPrefix) {
  return {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <Heading level="1">{children}</Heading>,
      [BLOCKS.HEADING_2]: (node, children) => <Heading level="2">{children}</Heading>,
      [BLOCKS.HEADING_3]: (node, children) => <Heading level="3">{children}</Heading>,
      [BLOCKS.HEADING_4]: (node, children) => <Heading level="4">{children}</Heading>,
      [BLOCKS.HEADING_5]: (node, children) => <Heading level="5">{children}</Heading>,
      [BLOCKS.HEADING_6]: (node, children) => <Heading level="6">{children}</Heading>,
      [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <StyledImg
          src={node.data.target.fields.file['en-US'].url}
          alt={node.data.target.fields.title['en-US']}
          width={node.data.target.fields.file['en-US'].details.image.width}
          height={node.data.target.fields.file['en-US'].details.image.height}
        />
      ),
      [INLINES.HYPERLINK]: (node) =>
        node.data.uri.startsWith('http') || !Link ? (
          <Anchor href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {node.content[0].value}
          </Anchor>
        ) : (
          <GatsbyAnchor Link={Link} href={node.data.uri}>
            {node.content[0].value}
          </GatsbyAnchor>
        ),
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const href = linkResolver({
          type: node.data.target.sys.contentType.sys.id,
          slug: node.data.target.fields.slug['en-US'],
          postPrefix,
        });

        return (
          <GatsbyAnchor Link={Link} href={href}>
            {node.content[0].value}
          </GatsbyAnchor>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node) => (
        <Anchor
          href={node.data.target.fields.file['en-US'].url}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          {node.content[0].value}
        </Anchor>
      ),
    },
  };
}

export default function({ Link, body }) {
  return (
    <StoryContext.Consumer>
      {(postPrefix) =>
        (body && body.json && documentToReactComponents(body.json, options(Link, postPrefix))) || (
          <Paragraph>{body}</Paragraph>
        )
      }
    </StoryContext.Consumer>
  );
}
