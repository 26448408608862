import React from 'react';
import { Box, ResponsiveContext, ThemeContext } from 'grommet';
import StoryHeader from '../components/StoryHeader';
import StoryStickyCTA from '../components/StoryStickyCTA';
import CtaBanner from '../components/CtaBanner';
import CtaBannerAlt from '../components/CtaBannerAlt';
import StoryList from '../components/StoryList';
import RichText from '../components/RichText';
import SocialIcons from '../components/SocialIcons';

export default function({ doc, Link }) {
  const {
    body,
    header,
    floatingCta,
    spacerNavigator,
    closingBackground,
    relatedPosts,
    closingNavigator,
    meta,
  } = doc;

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size !== 'medium' && size !== 'large';

        return (
          <>
            <StoryHeader {...header} />
            <Box
              pad={isSmall ? 'large' : 'xlarge'}
              direction="row"
              wrap
              style={{ position: 'relative' }}
            >
              {isSmall && <SocialIcons title={meta.title} />}
              <Box basis={isSmall ? 'full' : '2/3'}>
                {body && (
                  <ThemeContext.Extend
                    value={{
                      heading: {
                        level: {
                          '1': { medium: { maxWidth: 'auto' } },
                          '2': { medium: { maxWidth: 'auto' } },
                          '3': { medium: { maxWidth: 'auto' } },
                          '4': { medium: { maxWidth: 'auto' } },
                          '5': { medium: { maxWidth: 'auto' } },
                          '6': { medium: { maxWidth: 'auto' } },
                        },
                      },
                      paragraph: { medium: { maxWidth: 'auto' } },
                    }}
                  >
                    <RichText Link={Link} body={body} />
                  </ThemeContext.Extend>
                )}
              </Box>
              <Box align={isSmall ? 'stretch' : 'end'} basis={isSmall ? 'full' : '1/3'}>
                {floatingCta && <StoryStickyCTA {...floatingCta} title={meta.title} Link={Link} />}
              </Box>
            </Box>
            {spacerNavigator && <CtaBanner Link={Link} {...spacerNavigator} />}
            <Box background={closingBackground} pad={{ bottom: 'xlarge' }}>
              {relatedPosts && (
                <StoryList
                  Link={Link}
                  headline={relatedPosts.headline}
                  background={closingBackground}
                  cards={relatedPosts.cards}
                />
              )}
              {closingNavigator && <CtaBannerAlt Link={Link} {...closingNavigator} />}
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
