export { fransuiteTheme, insightsofficerTheme, taxverseTheme } from './grommet/Theme';
export { default as DetailsFransuite } from './templates/DetailsFransuite';
export { default as DetailsInsightsOfficer } from './templates/DetailsInsightsOfficer';
export { default as DetailsTaxVerse } from './templates/DetailsTaxVerse';
export { default as Footer } from './components/Footer';
export { default as FormModal, FormModalContext, useShowModal } from './components/FormModal';
export { default as Header } from './components/Header';
export { default as Home } from './templates/Home';
export { default as HomeTaxVerse } from './templates/HomeTaxVerse';
export { default as Layout } from './templates/Layout';
export { default as NotFound } from './templates/NotFound';
export { default as PricingTaxVerse } from './templates/PricingTaxVerse';
export { default as SEO } from './templates/SEO';
export { default as StoriesFransuite } from './templates/StoriesFransuite';
export { default as StoriesInsightsOfficer } from './templates/StoriesInsightsOfficer';
export { default as StoriesTaxVerse } from './templates/StoriesTaxVerse';
export { default as Story } from './templates/Story';
export { default as StoryContext } from './templates/StoryContext';
export { linkResolver } from './utils';
