import React from 'react';
import Compliance from '../components/Compliance';
import CtaBanner from '../components/CtaBanner';
import Hero from '../components/HeroInteriorAlt';
import HighlightList from '../components/HighlightList';
import LogoGridProduct from '../components/LogoGridProduct';
import StoryList from '../components/StoryList';

export default function({ doc, Link }) {
  if (!doc) return null;

  const { hero, highlights, logos, cta, stories, compliance } = doc;

  return (
    <>
      <Hero Link={Link} {...hero} />
      <HighlightList Link={Link} {...highlights} border />
      <Compliance {...compliance} />
      <LogoGridProduct Link={Link} {...logos} />
      <CtaBanner Link={Link} {...cta} />
      <StoryList Link={Link} {...stories} />
    </>
  );
}
