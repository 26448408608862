const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export function forceSlashes(str) {
  const url = !str.startsWith('http') && !str.startsWith('/') ? `/${str}` : str;
  return !url.endsWith('/') && !url.includes('#') && !url.includes('?') ? `${str}/` : str;
}

export function formatPrice(num) {
  return currency.format(num);
}

export function isAnchor(str) {
  return str.includes('#');
}

export function isExternal(str) {
  return str.startsWith('http');
}

export function linkResolver({ slug, type, postPrefix }) {
  if (isExternal(slug)) return slug;
  if (slug === 'home') return '/';

  switch (type) {
    case 'ContentfulHomePage':
    case 'homePage':
      return '/';
    case 'ContentfulForm':
    case 'form':
      return `/forms/${slug}`;
    case 'ContentfulPost':
    case 'post':
      return `/${postPrefix}/${slug}`;
    default:
      return slug;
  }
}

export function queryString(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
}
