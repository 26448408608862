import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import SectionHeader from '../SectionHeader';
import PricingCardTaxVerse from './PricingCardTaxVerse';

export default function({ Link, background, headline, body, tiers }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = ['small', 'hero'].includes(size);

        return (
          <Box
            id="pricing"
            pad={
              small
                ? { horizontal: 'large', bottom: 'large' }
                : { horizontal: 'xlarge', bottom: 'xlarge' }
            }
            style={{ background: `url(${background}) top center` }}
          >
            {(headline || body) && <SectionHeader headline={headline} body={body} />}
            {tiers && (
              <Box
                direction={small ? 'column' : 'row'}
                gap="large"
                alignSelf="stretch"
                alignContent="center"
                margin={{ bottom: 'xlarge' }}
              >
                {tiers.map((tier, index) => (
                  <PricingCardTaxVerse key={tier.id} Link={Link} {...tier} first={index === 0} />
                ))}
              </Box>
            )}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
