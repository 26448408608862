import React from 'react';
import CtaBanner from '../components/CtaBanner';
import CtaSection from '../components/CtaSection';
import HeroMain from '../components/HeroMain';
import HighlightList from '../components/HighlightList';
import ThreeUp from '../components/ThreeUp';
import TimelineList from '../components/TimelineList';

export default function({ doc, Link }) {
  if (!doc) return null;

  const { hero, highlights, timeline, threeUp, threeUpCta, cta, footerCta } = doc;

  return (
    <>
      <HeroMain Link={Link} {...hero} background="light-6" />
      <TimelineList Link={Link} {...timeline} />
      <HighlightList Link={Link} {...highlights} />
      <ThreeUp {...threeUp} />
      <CtaBanner Link={Link} {...threeUpCta} />
      <CtaSection Link={Link} {...cta} />
      <CtaBanner Link={Link} {...footerCta} />
    </>
  );
}
