import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Heading, ResponsiveContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';
import SocialIcons from '../SocialIcons';

const Container = styled(Box)`
  position: sticky;
  top: ${({ theme, siteHeaderHeight }) =>
    `${siteHeaderHeight + parseInt(theme.global.edgeSize.xlarge, 10)}px`};
  margin-top: ${({ isLarge, theme }) =>
    isLarge ? `${-1.5 * parseInt(theme.global.edgeSize.xlarge, 10)}px` : 0};
`;

const CTAContainer = styled(Box)`
  max-width: ${({ isLarge }) => (isLarge ? '280px' : 'auto')};
`;

export default function StoryStickyCTA(props) {
  const [siteHeaderHeight, setSiteHeaderHeight] = useState(0);

  useEffect(() => {
    const siteHeaderElement = document.getElementById('site-header');
    if (siteHeaderElement) {
      setSiteHeaderHeight(siteHeaderElement.offsetHeight);
    }
  }, []);

  const { Link, headline, ctaText, ctaLink, background, buttonColor, title } = props;

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isLarge = size === 'medium' || size === 'large';
        const isSmall = size === 'small' || size === 'hero';

        return (
          !isSmall && (
            <Container isLarge={isLarge} siteHeaderHeight={siteHeaderHeight}>
              <CTAContainer
                background={background}
                pad={{ vertical: 'medium', horizontal: 'medium' }}
                isLarge={isLarge}
              >
                <Heading level={4} margin={{ top: '0' }}>
                  {headline}
                </Heading>
                <GatsbyButton
                  Link={Link}
                  href={ctaLink}
                  label={ctaText}
                  primary
                  color={buttonColor}
                />
              </CTAContainer>
              <SocialIcons title={title} />
            </Container>
          )
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
