import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import SectionHeader from '../SectionHeader';
import TimelineListAccordion from './TimelineListAccordion';
import TimelineListTabs from './TimelineListTabs';

export default function({ headline, body, cards }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const mobile = ['small', 'hero'].includes(size);
        return (
          <Box pad={{ horizontal: mobile ? 'medium' : 'xlarge' }}>
            {(headline || body) && <SectionHeader headline={headline} body={body} />}
            {mobile ? <TimelineListAccordion cards={cards} /> : <TimelineListTabs cards={cards} />}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
